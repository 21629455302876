/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: '/',
    name: 'Home',
    slug: 'home',
    icon: 'HomeIcon',
    i18n: 'Home'
  },
  {
    url: 'https://support.travelgenix.io',
    name: 'Support',
    slug: 'external',
    target: '_blank',
    icon: 'LifeBuoyIcon',
    i18n: 'Support',
    eitherPermissions: 'apps_support_read,apps_support_submit'
  },
  {
    // Caution do not update header value as this is a dependency of Main.vue
    header: 'Company',
    icon: 'PackageIcon',
    i18n: 'Company',
    items: [
      // Will be set dynamically in Main.vue
    ]
  },
  {
    header: 'Applications',
    icon: 'PackageIcon',
    i18n: 'Applications',
    items: [
      // Will be set dynamically in Main.vue
    ]
  }
]
