<template>
  <div class="the-navbar__user-meta flex items-center">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
      <small>{{ activeUserInfo.companyRole }}</small>
    </div>

    <vs-dropdown vs-custom-content class="cursor-pointer">
      <div class="con-img ml-3">
        <vs-avatar class="my-0" :src="activeUserInfo.photoURL" size="40px" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li 
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="routeTo({ name: 'account'})"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Account</span>
          </li>
          <li 
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="routeTo({ name: 'account-selector'})"
          >
            <feather-icon icon="BookmarkIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Start Page</span>
          </li>

          <li v-if="activeUserInfo.isHostAdmin"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="routeTo({ name: 'admin-crm'})"
          >
            <feather-icon icon="ExternalLinkIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">All Apps</span>
          </li>

          <vs-divider class="m-1" />
          <li class="flex py-2 px-4 cursor-none">
            <small class="ml-0 text-muted">Companies</small>
          </li>
          <li
            v-for="(company, index) in companies" 
            :key="'c'+index" 
            :class="{ 'bg-primary': company.id === activeCompanyId, 'text-white': company.id === activeCompanyId}"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="switchComnpany(company)"
          >
            <span class="ml-2">{{company.name}}</span>
          </li>

          <vs-divider class="m-1" />
          <li class="flex py-2 px-4 cursor-none">
            <small class="ml-0 text-muted">Applications</small>
          </li>
          <li
            v-for="(app, index) in applications" 
            :key="index" 
            :class="{ 'bg-primary': app.id === activeApplicationId, 'text-white': app.id === activeApplicationId}"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="switchApplication(app)"
          >
            <span class="ml-2">{{app.name}}</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    applications () {
      return this.$store.state.Applications || []
    },
    activeApplicationId () {
      return this.$store.state.ActiveApplicationId
    },
    companies () {
      return this.$store.state.Companies || []
    },
    activeCompanyId () {
      return this.$store.state.AppActiveUser.companyId
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then((res) => {
        this.$router.push({ name: "page-login" });
      });
    },
    routeTo (route) {
      this.$router.push(route);
    },
    switchApplication (app) {
      this.$store.commit('UPDATE_ACTIVE_APP', app)
    },
    switchComnpany (company) {
      // Handle mutation of activeCompanyId, Main.vue is watching activeCompanyId and will handle context switching
      this.$store.commit('UPDATE_ACTIVE_COMPANY', { id: company.id, name: company.name })
    }
  },
};
</script>
<style scoped lang="scss">
.vs-dropdown--menu ul {
    width: 250px;
}
</style>