<template>
  <travio-modal :zIndex="99999" v-if="showPopup" width='350' height="90">
    <h3 slot="header">Session Expired Due to Inactivity</h3>
    <div slot="body">
      <p class="mb-2">You are about to be logged out due to inactivity. To continue your work click resume session.</p>
      <p class="mb-0">{{secondsLeftBeforeLogout}} seconds left</p>
    </div>
      
    <div slot="footer" class="flex flex-wrap justify-center">
      <vs-button class="w-full" @click="resetTimers(true, false)" color="primary" type="filled">Resume Session</vs-button>      
    </div>
  </travio-modal>

</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'

export const maxIdleTime = 60 * 60 * 1000 // 60 minutes
export const initialCountdown = 30 * 1000 // 30 seconds

export default {
  components: {
    TravioModal
  },
  data() {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress'],
      warningTimer: null,
      logoutTimer: null,
      countdownInterval: null,

      showPopup: false,
      countDownToLogout: initialCountdown,
      resetIdleTimeout: false
    }
  },
  computed: {
    secondsLeftBeforeLogout() {
      // + 1000 to offset initial subtraction of 1000
      return (this.countDownToLogout + 1000) / 1000;
    }
  },
  mounted () {
    // If user closes tab and returns back but session is already expired
    // Login function also needs to set last_active_datetime, otherwise user will be continuously logged out
    let lastActiveDate = localStorage.getItem('last_active_datetime') && new Date(localStorage.getItem('last_active_datetime'))
    if(lastActiveDate) {
      var diffInMilliseconds = (new Date()).getTime() - lastActiveDate.getTime()
      if(diffInMilliseconds > maxIdleTime) {
        console.log('logging out: ' + diffInMilliseconds / 1000)
        this.logout()
        return
      }
    }

    this.events.forEach(event => {
      window.addEventListener(event, this.resetTimersFromEvents)
    });
    this.setTimers();
  },
  destroyed () {
    this.resetTimers(true)
  },
  methods: {
    setTimers () {
      this.warningTimer = setTimeout(() => {
        
        if(this.hasActivityFromOtherTabs()) {
          this.resetTimers(true, true)
          return
        }

        this.showPopup = true
        // Initially subtract to countdown to prevent race condition
        this.countDownToLogout -= 1000
        this.countdownInterval = setInterval(() => this.countDownToLogout -= 1000 , 1000)

        this.logoutTimer = setTimeout(() => {
          this.logout()
        }, initialCountdown)

      }, maxIdleTime )

     
    },
    resetTimersFromEvents() {
      this.resetTimers(false, false)
    },
    resetTimers (forceReset, doNotLogActivity) {
      // check if the countdown already started
      // if not user needs to click reset for the session to continue
      if(this.countDownToLogout == initialCountdown || forceReset) {
        if(!doNotLogActivity) {
          localStorage.setItem('last_active_datetime', new Date());
        }
        clearTimeout(this.warningTimer)
        clearTimeout(this.logoutTimer)
        clearInterval(this.countdownInterval)
        this.setTimers()
        this.showPopup = false
        this.countDownToLogout = initialCountdown
      } 
    },
    logout() {
      this.$store.dispatch("auth/logout").then((res) => {
        this.$router.push({ name: "page-login", query: { redirect: this.$router.currentRoute.path } });
        location.reload()
      });
    },
    hasActivityFromOtherTabs() {
      let lastActiveDate = localStorage.getItem('last_active_datetime') && new Date(localStorage.getItem('last_active_datetime'))
      if(lastActiveDate) {
        var diffInMilliseconds = (new Date()).getTime() - lastActiveDate.getTime()
        // console.log("Diff in sseconds: " + diffInMilliseconds/1000)
        return diffInMilliseconds < maxIdleTime
      }
      return false
    }
  }
}
</script>

<style>

</style>