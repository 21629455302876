<template>
  <img
    src="@/assets/images/logo/header-logo.svg"
    class="company-logo-image"
  />
</template>

<script>
import defaultLogo from "@/assets/images/logo/header-logo.svg";

export default {
  data() {
    return {};
  },
  computed: {
    companyLogo() {
      return this.$store.state.AppActiveUser &&
        this.$store.state.AppActiveUser.companyLogo;
    },
  },
  methods: {
    setAltImgForThumbnail(event) {
      event.target.src = defaultLogo;
    },
  },
};
</script>

<style scoped>
.company-logo-image {
  border-radius: 3px;
}
</style>
